import React from "react";
import Navbar from "./sections/navbar/Navbar";
import Header from "./sections/header/Header";
import WorkExperience from "./sections/work-experience/WorkExperience";
import Portfolio from "./sections/portfolio/Portfolio";
import About from "./sections/about/About";
import Footer from "./sections/footer/Footer";
import Contact from "./sections/contact/Contact";
// import Modal from "./components/Modal";
import Theme from './theme/Theme';
import { useThemeContext } from "./context/theme-context";

const App = () => {
  const {themeState} = useThemeContext();

  return (
    <main className={`${themeState.primary} ${themeState.background}`}>
      
      <Navbar />
      <Header />
      <About />
      <WorkExperience />
      <Portfolio />
      <Contact />
      <Footer />
      <Theme/>
      {/* <FloatingNav /> */}
    </main>
  );
};

export default App;
