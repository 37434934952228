import TerryLogo from "../../assets/TerryLogo.png";
import data from "./data";
import "./navbar.css";
import {FaPaintBrush} from 'react-icons/fa';
import {useModalContext} from '../../context/modal-contex';


const Navbar = () => {
  const {showModalHandler} = useModalContext();
  return (
    <nav>
      <div className="container nav__container">
        <a href="index.html" className="nav__logo">
          <img src={TerryLogo} alt="TerryLogo" />
        </a>
        <ul className="nav__menu">
          {data.map((item) => (
            <li key={item.id}>
              {" "}
              <a href={item.link}>{item.title}</a>{" "}
            </li>
          ))}
        </ul>
        <button id="theme__icon" onClick={showModalHandler}>
          {" "}
          <FaPaintBrush/>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
